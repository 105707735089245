import React from "react";

export default function FurgonetkaLogoSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 70 14.77"
      className="w-full h-full"
    >
      
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path            
            fill="#0f8ecd"
            d="M6.78,7.9a1.55,1.55,0,0,1,1-2.14c1.2-.57,2.32-1.31,3.6-2C10,2.93,8.7,2.2,7.42,1.5a1.48,1.48,0,0,0-1.37,0c-1.33.7-2.62,1.46-4,2.26L4.15,4.94c1.13.64,1.13.63.94,2.09L2,5.29c-.69-.39-.63-.38-.71.41a1.35,1.35,0,0,0,1,1.62,2.61,2.61,0,0,1,.43.24c1,.59,1.08.66.81,2C3,9.28,2.44,9,1.93,8.68s-.62-.15-.6.37c0,.87,0,1.73,0,2.62C0,11.2,0,11.18,0,10c0-2,0-4,0-6.08A.89.89,0,0,1,.51,3C2,2.22,3.38,1.39,4.8.56a3.46,3.46,0,0,1,3.75,0C10,1.38,11.4,2.21,12.84,3a.88.88,0,0,1,.53.88q0,3.29,0,6.57a.81.81,0,0,1-.48.84C11,12.39,9.05,13.49,7.14,14.62a.75.75,0,0,1-.9,0A18.55,18.55,0,0,0,3.91,13.3a1.53,1.53,0,0,1-1-2.14c1,.6,2.11,1.19,3.14,1.82a1,1,0,0,0,1.15,0q2.12-1.27,4.28-2.48a.88.88,0,0,0,.51-.9c0-1.36,0-2.73,0-4.1C12,5,11.9,5,11.47,5.2,9.93,6.1,8.37,7,6.78,7.9Z"
          />
          <path
            class="dark-img"
            fill="#494b52"
            d="M37,8.36c0,.75,0,1.51,0,2.26a2.15,2.15,0,0,1-2.16,2.25,6.31,6.31,0,0,1-2-.13c-.48-.11-.41-.39-.36-.74s.29-.48.65-.37a4,4,0,0,0,1.47.13,1.12,1.12,0,0,0,.86-.42.65.65,0,0,0,.15-.72c-.14-.25-.38-.08-.58,0a2.33,2.33,0,0,1-2.91-2,2.7,2.7,0,0,1,2.09-3.38,6.63,6.63,0,0,1,2.39.14c.35.06.44.27.44.6,0,.8,0,1.6,0,2.4Zm-1.29-.51a6.77,6.77,0,0,1,0-.78c.09-.69-.3-.86-.89-.82a1.34,1.34,0,0,0-1.31,1,1.83,1.83,0,0,0,.36,2,1.37,1.37,0,0,0,1.47.16c.49-.17.34-.61.36-1A5.42,5.42,0,0,0,35.68,7.85Z"
          />
          <path
            class="dark-img"
            fill="#494b52"
            d="M60.21,6.78c0-1.13,0-2.26,0-3.39,0-.74.48-.69.93-.75s.42.4.43.68c0,1.09,0,2.17,0,3.25,0,.18-.08.43.15.5s.31-.16.44-.29a5.6,5.6,0,0,0,.8-.89,1.53,1.53,0,0,1,1.6-.62c.4,0,.39.18.16.42-.48.52-.95,1-1.45,1.55s-.43.64,0,1a11.35,11.35,0,0,1,1.57,2c.29.42.32.63-.31.65a1.25,1.25,0,0,1-1.28-.66,6.23,6.23,0,0,0-1.16-1.41c-.11-.11-.22-.3-.41-.2s-.11.24-.11.38c0,.38,0,.76,0,1.13s0,.76-.64.76-.74-.19-.72-.74C60.23,9.05,60.21,7.91,60.21,6.78Z"
          />
          <path
            class="dark-img"
            fill="#494b52"
            d="M53.1,8.5H51.83c-.2,0-.46-.05-.54.22a.64.64,0,0,0,.17.59,1.67,1.67,0,0,0,1.45.58,8,8,0,0,0,.84-.1c.29,0,.59-.2.68.3s0,.71-.49.81a5.63,5.63,0,0,1-1.83.11c-1.73-.24-2.64-1.77-2.23-3.74a2.6,2.6,0,0,1,3.31-2,2.54,2.54,0,0,1,1.65,2.93.41.41,0,0,1-.47.32H53.1Zm-.78-1H53c.59,0,.71-.24.41-.74a1.12,1.12,0,0,0-1.76-.18c-.2.21-.41.47-.32.75s.45.13.68.17A2.17,2.17,0,0,0,52.32,7.52Z"
          />
          <path
            class="dark-img"
            fill="#494b52"
            d="M40.62,11a2.7,2.7,0,0,1-2.73-2.95,2.72,2.72,0,1,1,5.42,0A2.7,2.7,0,0,1,40.62,11ZM42,8.11a5.77,5.77,0,0,0-.15-.83,1.25,1.25,0,0,0-2.45,0,2.59,2.59,0,0,0,.09,1.86,1.18,1.18,0,0,0,1.1.76,1.21,1.21,0,0,0,1.18-.75A2.86,2.86,0,0,0,42,8.11Z"
          />
          <path
            class="dark-img"
            fill="#494b52"
            d="M17.05,7.18c0-1,0-2,0-3,0-.5.15-.72.68-.71,1.2,0,2.4,0,3.6,0,.45,0,.63.16.63.62s-.2.61-.64.6c-.73,0-1.46,0-2.19,0-.52,0-.74.18-.68.7a2,2,0,0,1,0,.35c-.08.6.13.89.78.82.37,0,.75,0,1.12,0s.57.18.57.59-.15.62-.58.57h-.21c-1.68,0-1.68,0-1.68,1.66,0,.26,0,.52,0,.78,0,.53-.07.82-.71.82s-.72-.36-.68-.85V7.18Z"
          />
          <path
            class="dark-img"
            fill="#494b52"
            d="M27.05,8.05c0,.7,0,1.41,0,2.11a.6.6,0,0,1-.59.71,8.79,8.79,0,0,1-2.11.15,1.93,1.93,0,0,1-1.91-1.67,19.46,19.46,0,0,1-.1-3.66c0-.51.41-.41.72-.42s.62,0,.61.47c0,.94,0,1.89,0,2.83S24.14,9.9,25,9.89c.49,0,.73-.17.72-.71,0-1.08,0-2.16,0-3.24,0-.54.21-.69.71-.69s.67.2.65.68C27,6.63,27.05,7.34,27.05,8.05Z"
          />
          <path
            class="dark-img"
            fill="#494b52"
            d="M70,8.63v.92c0,1.41.16,1.22-1.29,1.43a4.65,4.65,0,0,1-2-.09,1.62,1.62,0,0,1-1.22-1.63,1.58,1.58,0,0,1,1.11-1.62,3.82,3.82,0,0,1,1.54-.19c.19,0,.45.14.56-.13a.68.68,0,0,0-.24-.76,1.76,1.76,0,0,0-1.62-.23c-.39.07-.68.06-.75-.45,0-.35.06-.54.4-.6a6.09,6.09,0,0,1,1.41-.15A1.92,1.92,0,0,1,70,7.29ZM68,10c.43,0,.75-.1.73-.77s-.18-.82-.76-.81c-.75,0-1.21.32-1.18.83C66.85,9.89,67.36,9.93,68,10Z"
          />
          <path
            class="dark-img"
            fill="#494b52"
            d="M44.21,8.13c0-.71,0-1.42,0-2.12,0-.4.13-.61.54-.67a7.78,7.78,0,0,1,2.31-.14A1.85,1.85,0,0,1,48.85,7c.08,1.12,0,2.26,0,3.38,0,.51-.34.47-.68.48s-.62-.08-.62-.54c0-.94,0-1.88,0-2.82s-.45-1.26-1.36-1.22c-.47,0-.67.21-.66.69,0,1.06,0,2.12,0,3.17,0,.48-.09.74-.66.74s-.74-.21-.71-.75S44.21,8.81,44.21,8.13Z"
          />
          <path
            class="dark-img"
            fill="#494b52"
            d="M55.82,6.72c0-.77,0-1.55,0-2.33s.41-.68.86-.73.44.37.45.66c0,.94,0,.94,1,.95.14,0,.28,0,.42,0,.4,0,.61.11.62.55s-.2.55-.61.56c-1.43,0-1.43.06-1.43,1.5v.56c0,1.28.3,1.52,1.58,1.36.39-.05.48.12.56.44s0,.52-.33.64a2.1,2.1,0,0,1-3.1-2C55.81,8.13,55.82,7.43,55.82,6.72Z"
          />
          <path
            class="dark-img"
            fill="#494b52"
            d="M28.24,8.17c0-.68,0-1.36,0-2,0-.47.18-.67.62-.77a5.94,5.94,0,0,1,2.3-.14c.38.06.54.21.46.61s-.13.67-.61.54a1.34,1.34,0,0,0-.35,0c-1-.06-1.06,0-1.06,1s0,2,0,3c0,.5-.19.65-.67.64s-.71-.1-.69-.62C28.26,9.58,28.24,8.88,28.24,8.17Z"
          />
        </g>
      </g>
    </svg>
  );
}
