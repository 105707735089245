import React from "react";

export default function OverDriveLogoSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 10.25" className="w-full h-full">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            class="cls-1"
            fill="#006595"
            d="M12.67,4.26a15.8,15.8,0,0,1-1,2.48A7.86,7.86,0,0,1,3.12,9.93,4.07,4.07,0,0,1,.59,3.83,7.85,7.85,0,0,1,9.76.42,4.43,4.43,0,0,1,12.67,4.26ZM6.58,1.61a4.9,4.9,0,0,0-4.1,2.66A2.85,2.85,0,0,0,4.4,8.52,5.1,5.1,0,0,0,10,6a3.69,3.69,0,0,0,.34-2C10.11,2.5,8.79,1.62,6.58,1.61Z"
          />
          <path
            class="cls-1"
            fill="#006595"
            d="M34.25,9.92C35,6.8,35.62,3.77,36.32.74c0-.19.3-.48.46-.48a33.92,33.92,0,0,1,4.95.23A3.68,3.68,0,0,1,44.5,5.8a6.4,6.4,0,0,1-5.74,4.1C37.31,10,35.83,9.92,34.25,9.92ZM36.7,8.3a5.55,5.55,0,0,0,4.09-.67A5.24,5.24,0,0,0,42.3,6C43.53,3.88,42.21,2,39.43,1.8c-1.3-.08-1.29-.08-1.56,1.16-.08.39-.19.78-.27,1.17Z"
          />
          <path
            class="cls-1"
            fill="#006595"
            d="M62.79,7.06a1.56,1.56,0,0,0,1.47,1.6,3.49,3.49,0,0,0,2.15-.33,4.08,4.08,0,0,1,2.7-.53c0,.11,0,.23-.07.3A5.87,5.87,0,0,1,62.83,10,3,3,0,0,1,60.9,5.88a5.72,5.72,0,0,1,7.27-3.07,2.94,2.94,0,0,1,1.7,3.62.73.73,0,0,1-.83.64c-1.8,0-3.59,0-5.39,0ZM63,5.81c1.47,0,2.83,0,4.19,0,.25,0,.71-.08.71-.16a1.74,1.74,0,0,0-.18-.89,1.24,1.24,0,0,0-.5-.49A3.13,3.13,0,0,0,63,5.81Z"
          />
          <path
            class="cls-1"
            fill="#006595"
            d="M22.39,7.11C22.57,8.35,23.73,9,25.18,8.7a3.25,3.25,0,0,0,.9-.33,3.33,3.33,0,0,1,2.71-.55,2.86,2.86,0,0,1-.35.53,5.77,5.77,0,0,1-5.63,1.74,3.06,3.06,0,0,1-2.23-4.38,5.76,5.76,0,0,1,7.16-2.93,3,3,0,0,1,1.76,3.6.76.76,0,0,1-.88.69c-1.79,0-3.59,0-5.38,0C23,7.06,22.68,7.09,22.39,7.11Zm.27-1.3c1.48,0,2.86,0,4.25,0,.75,0,.9-.27.53-.93a1.73,1.73,0,0,0-.53-.58A3.1,3.1,0,0,0,22.66,5.81Z"
          />
          <path
            class="cls-1"
            fill="#006595"
            d="M13.13,2.75c2-.23,2-.23,2.44,1.6.22,1,.45,2,.67,3,0,0,.07.07.2.2.15-.22.3-.41.43-.61.71-1.13,1.46-2.24,2.11-3.4a1.46,1.46,0,0,1,1.65-.9,6.34,6.34,0,0,0,1,0,4.21,4.21,0,0,1-.31.61C19.85,5.36,18.37,7.41,17,9.51a1.35,1.35,0,0,1-1.62.49c-.25-.05-.48-.54-.58-.88-.52-1.89-1-3.79-1.51-5.69C13.19,3.22,13.17,3,13.13,2.75Z"
          />
          <path
            class="cls-1"
            fill="#006595"
            d="M56.61,7.7,59,3.87c.84-1.33,1.1-1.43,2.82-1.06-.21.3-.39.58-.59.85-1.35,1.92-2.74,3.81-4,5.76a1.37,1.37,0,0,1-1.66.58c-.27-.07-.52-.58-.62-.93-.52-1.87-1-3.75-1.48-5.62a6,6,0,0,1-.12-.77,10.05,10.05,0,0,1,1.66,0,.94.94,0,0,1,.55.6c.3,1.24.55,2.49.82,3.74C56.41,7.21,56.49,7.37,56.61,7.7Z"
          />
          <path
            class="cls-1"
            fill="#006595"
            d="M33,3.15,35,2.35c.09,1.32-.05,1.53-1,1.84a2,2,0,0,0-1.46,1.55C32.28,6.85,32,8,31.8,9.08c-.12.8-.44,1.11-1.24,1a8,8,0,0,0-1,0c.36-1.65.69-3.19,1-4.74.15-.72.32-1.44.48-2.17s.68-.45,1.09-.47S33,2.52,33,3.15Z"
          />
          <path
            class="cls-1"
            fill="#006595"
            d="M44.37,10.07c.52-2.4,1-4.65,1.53-6.89a.87.87,0,0,1,.66-.47c.46,0,1.14-.37,1.25.43l2-.8c.14,1.3,0,1.54-1,1.85a2,2,0,0,0-1.47,1.53c-.26,1.14-.57,2.27-.76,3.42-.13.75-.44,1.06-1.21.93A6.65,6.65,0,0,0,44.37,10.07Z"
          />
          <path
            class="cls-1"
            fill="#006595"
            d="M52.62,2.79c-.38,1.73-.75,3.46-1.13,5.18l-.15.7c-.35,1.52-.54,1.63-2.28,1.26.4-1.8.79-3.6,1.19-5.4l.09-.39C50.67,2.52,50.78,2.45,52.62,2.79Z"
          />
          <path
            class="cls-1"
            fill="#006595"
            d="M51,1.6c0-1.55.09-1.47,2-1.28C53.05,1.78,52.64,2,51,1.6Z"
          />
        </g>
      </g>
    </svg>
  );
}
