import React from "react";

export default function NASCARLogoSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 11.65" className="h-full w-full">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            class=" dark-img"
            fill="#020202"
            d="M16.85,11.63c.59-2.11,1.16-4.18,1.74-6.25C19.06,3.69,19.55,2,20,.31c.06-.22.14-.29.37-.29q.82,0,1.65,0c.23,0,.3.07.34.3.32,2,.65,4,1,5.95,0,.05,0,.1.08.23l1-3.63c.24-.87.49-1.73.73-2.6,0-.14.05-.25.25-.25.68,0,1.36,0,2.06,0-.31,1.13-.61,2.24-.92,3.35-.73,2.63-1.47,5.27-2.19,7.9-.07.26-.16.38-.46.36-.53,0-1.06,0-1.58,0-.22,0-.28-.07-.32-.28-.33-2.06-.68-4.12-1-6.28-.06.18-.09.28-.12.38-.55,2-1.11,3.92-1.65,5.89-.07.23-.16.29-.4.29C18.16,11.62,17.53,11.63,16.85,11.63Z"
          />
          <path
            class=" dark-img"
            fill="#020202"
            d="M25.52,11.63c.51-1.8,1-3.57,1.49-5.34.41-1.46.82-2.92,1.22-4.38a.88.88,0,0,1,.34-.49C29.11,1,29.65.61,30.18.2a.69.69,0,0,1,.5-.17H34.2a.42.42,0,0,1,.42.2c.28.4.56.78.86,1.16a.53.53,0,0,1,.1.5q-1.34,4.73-2.65,9.46c-.06.21-.13.29-.36.29-.64,0-1.28,0-2,0l.82-3c.15-.52.15-.52-.38-.52-.68,0-1.36,0-2,0a.31.31,0,0,0-.35.27c-.26,1-.55,2-.81,3-.05.2-.14.26-.35.26C26.86,11.62,26.22,11.63,25.52,11.63Zm6.26-9.44c-.39,0-.78,0-1.18,0-.14,0-.22,0-.26.19-.3,1.12-.62,2.24-.94,3.36,0,.17,0,.21.18.21H32c.16,0,.21,0,.25-.2l.93-3.32c.06-.19,0-.24-.19-.24C32.58,2.2,32.18,2.19,31.78,2.19Z"
          />
          <path
            class=" dark-img"
            fill="#020202"
            d="M56.39,11.63c.26-.92.51-1.81.75-2.7.07-.25.29-.56.15-.73s-.5,0-.76,0c-.54,0-1.08,0-1.62,0a.4.4,0,0,0-.48.36c-.25,1-.53,1.91-.79,2.87a.29.29,0,0,1-.34.27c-.65,0-1.31,0-2,0L52.59,7Q53.31,4.47,54,1.91a.77.77,0,0,1,.31-.46L56,.2a.69.69,0,0,1,.5-.17H60a.43.43,0,0,1,.43.21,7.47,7.47,0,0,0,.63.85,1.06,1.06,0,0,1,.23,1.14c-.87,3-1.71,6.08-2.55,9.12a.32.32,0,0,1-.37.29C57.71,11.62,57.07,11.63,56.39,11.63ZM57.6,2.19c-.39,0-.78,0-1.17,0a.25.25,0,0,0-.31.22c-.3,1.1-.61,2.2-.92,3.3,0,.15-.05.25.17.24h2.42c.15,0,.2,0,.25-.2.3-1.11.61-2.22.92-3.33.06-.19,0-.23-.19-.23C58.38,2.2,58,2.19,57.6,2.19Z"
          />
          <path
            class=" dark-img"
            fill="#020202"
            d="M59.94,11.63c.35-1.26.7-2.5,1-3.74.7-2.51,1.41-5,2.1-7.54.07-.24.15-.33.41-.33,1.7,0,3.4,0,5.1,0A.44.44,0,0,1,69,.24c.28.41.58.82.89,1.22a.38.38,0,0,1,.07.37c-.41,1.45-.81,2.9-1.22,4.35a.63.63,0,0,1-.26.38c-.39.31-.78.64-1.18,1a.41.41,0,0,0-.18.42c.16,1.17.3,2.34.45,3.5,0,.15,0,.21-.17.2-.61,0-1.22,0-1.82,0-.18,0-.2-.08-.22-.22-.11-1-.24-1.93-.34-2.89,0-.28-.11-.42-.43-.39a6.53,6.53,0,0,1-1.17,0c-.24,0-.32.09-.37.29-.26,1-.54,1.93-.79,2.9-.06.22-.13.32-.39.31C61.25,11.62,60.61,11.63,59.94,11.63ZM63.77,6h2.65c.16,0,.18-.09.22-.21.31-1.11.61-2.22.93-3.32.06-.21,0-.23-.18-.23-.8,0-1.59,0-2.38,0a.22.22,0,0,0-.26.2c-.08.36-.2.7-.29,1C64.23,4.27,64,5.09,63.77,6Z"
          />
          <path
            class=" dark-img"
            fill="#020202"
            d="M37,9.43h2.65c.13,0,.19,0,.22-.15.22-.78.44-1.56.66-2.37H37.38a.51.51,0,0,1-.5-.25c-.27-.42-.56-.81-.85-1.21A.44.44,0,0,1,36,5c.31-1.06.61-2.12.91-3.19a.55.55,0,0,1,.22-.32L38.83.17A.62.62,0,0,1,39.24,0h3.59A.39.39,0,0,1,43.2.2c.29.43.6.84.9,1.25a.4.4,0,0,1,.08.38c-.15.45-.27.92-.4,1.38,0,.12-.06.22-.24.22-.68,0-1.35,0-2.08,0,.1-.34.18-.65.27-1,.05-.15.07-.25-.16-.25-.8,0-1.61,0-2.41,0-.12,0-.2,0-.23.15-.21.77-.42,1.54-.64,2.33h3.13a.46.46,0,0,1,.47.24,3.82,3.82,0,0,0,.53.72,1.32,1.32,0,0,1,.28,1.51c-.29.85-.5,1.73-.73,2.6a.78.78,0,0,1-.25.39c-.59.44-1.17.89-1.75,1.34a.61.61,0,0,1-.44.14H36.05a.47.47,0,0,1-.42-.22c-.29-.42-.6-.84-.91-1.25a.42.42,0,0,1-.08-.43c.13-.43.24-.86.35-1.29,0-.14.09-.23.28-.23.59,0,1.19,0,1.79,0,.23,0,.23.09.18.26S37.07,9.08,37,9.43Z"
          />
          <path
            fill="#020202"
            class=" dark-img"
            d="M13.36,11.63c-.63,0-1.26,0-1.89,0-.29,0-.32-.07-.24-.33l2.55-9.13C14,1.56,14.14.93,14.3.3A.32.32,0,0,1,14.67,0C16,0,17.25,0,18.53,0c.28,0,.32.06.25.32q-1.52,5.36-3,10.71c-.17.58-.17.58-.76.58Z"
          />
          <path
            class=" dark-img"
            d="M49.64,0c.6,0,1.19,0,1.79,0a.39.39,0,0,1,.39.2c.28.41.57.8.87,1.2a.41.41,0,0,1,.08.4c-.19.66-.37,1.32-.55,2,0,.15-.11.22-.28.21H50.18c-.25,0-.26-.07-.2-.27.14-.44.25-.88.37-1.32,0-.17,0-.24-.18-.24-.78,0-1.57,0-2.35,0-.17,0-.24.05-.28.21q-.95,3.41-1.9,6.79c0,.17,0,.24.16.24h2.38a.24.24,0,0,0,.28-.22c.11-.44.26-.87.37-1.31.05-.2.12-.28.34-.28.58,0,1.15,0,1.73,0,.2,0,.26,0,.19.26-.18.63-.36,1.27-.53,1.91a.67.67,0,0,1-.25.35c-.59.45-1.18.9-1.77,1.37a.55.55,0,0,1-.37.12H44.62a.42.42,0,0,1-.37-.19c-.31-.43-.62-.85-1-1.27a.42.42,0,0,1-.07-.43C44,7.12,44.7,4.5,45.43,1.87a.7.7,0,0,1,.28-.4C46.29,1,46.87.61,47.44.16A.59.59,0,0,1,47.85,0Z"
          />
          <path
            fill="#e3022d"
            d="M10.09,0c-.52,1.86-1,3.69-1.53,5.51S7.49,9.39,7,11.31c-.07.25-.17.35-.44.34-.52,0-1,0-1.55,0-.25,0-.28-.06-.21-.29.78-2.75,1.54-5.51,2.32-8.27.26-.93.53-1.86.78-2.8C7.92.08,8,0,8.22,0,8.82,0,9.43,0,10.09,0Z"
          />
          <path
            fill="#e3022d"
            d="M13.13,0,12.07,3.85c-.68,2.48-1.38,5-2.06,7.45a.39.39,0,0,1-.47.35c-.59,0-1.18,0-1.81,0,.42-1.51.84-3,1.25-4.45C9.62,4.89,10.27,2.6,10.9.3A.32.32,0,0,1,11.28,0C11.88,0,12.49,0,13.13,0Z"
          />
          <path
            fill="#fed65b"
            d="M.62,11.64c-.71,0-.7,0-.51-.67Q1.58,5.69,3,.42C3.13.11,3.25,0,3.57,0a3.21,3.21,0,0,0,.62,0c.28,0,.29.08.23.31C4,1.89,3.55,3.45,3.11,5,2.54,7.07,2,9.12,1.41,11.18c-.1.36-.25.53-.62.46Z"
          />
          <path
            fill="#fed65b"
            d="M2.82,11.64c-.69,0-.69,0-.51-.65Q3.8,5.7,5.26.39c.08-.29.21-.4.5-.36s.72-.13.88.08-.1.54-.18.83Q5,6,3.63,11.12C3.45,11.75,3.6,11.62,2.82,11.64Z"
          />
        </g>
      </g>
    </svg>
  );
}
