import React from "react";

export default function FirstfinancialLogoSvg() {
  return (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 23.6" className="w-full h-full">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path
            fill="#515151"
            className="dark-img"
            d="M33.48,17c0,1.67,0,3.33,0,5,0,1.41-.28,1.63-1.63,1.26a2.73,2.73,0,0,0-1.42.1c-1.41.32-1.86.1-2.43-1.2s-.61-1.35-2.13-1.26a3.56,3.56,0,0,1-.73,0c-1-.15-1.46.2-1.65,1.2a1.81,1.81,0,0,1-2.61,1.27c-.36-.16-.32-.38-.22-.66,1.08-3.16,2.15-6.32,3.24-9.48.37-1.1,1.35-.82,2.16-.88a1.42,1.42,0,0,1,1.53,1.16c.82,2.35,1.62,4.71,2.43,7.06.15.43.26.88.44,1.29s.06.88.57.78.26-.56.25-.87c0-1.74-.06-3.46-.1-5.19,0-1.1,0-2.19-.05-3.28a.74.74,0,0,1,.73-.86c2.13-.25,2.51-.1,3.2,1.64a28.65,28.65,0,0,0,2,4.07c.13.22.19.52.57.61a4.53,4.53,0,0,0,.1-1.51c0-1.12,0-2.25,0-3.37s.29-1.52,1.21-1.51,1.12.34,1.12,1.48c0,2.55,0,5.1,0,7.65,0,2,0,2-2,2a1.54,1.54,0,0,1-1.61-1.07A36.58,36.58,0,0,0,34.2,17.8c-.16-.28-.34-.56-.51-.83Zm-7.67-2h-.2c-.25.82-.51,1.64-.76,2.46-.37,1.21-.32,1.26,1,1.21a3.42,3.42,0,0,0,.45,0c.36-.05.56-.24.45-.62Z"
          />
          <path
            fill="#515151"
            className="dark-img"
            d="M13.47,16.88v1.28c0,1.39,0,2.79,0,4.18,0,.75-.28,1.14-1.06,1.15s-1.17-.3-1.17-1.14q0-4.5,0-9a.76.76,0,0,1,.7-.87c1.89-.39,2.41-.14,3.2,1.61.66,1.46,1.36,2.91,2.05,4.36.11.23.14.54.49.61.3-.26.17-.62.18-.93,0-1.45,0-2.91,0-4.37,0-1,.32-1.34,1.18-1.36s1.14.36,1.14,1.38c0,2.91,0,5.82,0,8.73,0,.55-.19.89-.75.93-2.07.14-2.3.28-3.12-1.6-.63-1.45-1.41-2.82-2.15-4.22A1.14,1.14,0,0,0,13.47,16.88Z"
          />
          <path
            fill="#515151"
            className="dark-img"
            d="M13.66.22a8.38,8.38,0,0,1,2.45.11,3.27,3.27,0,0,1,2.73,2.14A2.89,2.89,0,0,1,18,5.69a1.32,1.32,0,0,0-.14,2c.67.9,1.25,1.87,1.89,2.79.26.39.17.57-.27.7a2.49,2.49,0,0,1-2.61-.86A8.18,8.18,0,0,1,15.6,8.1c-.24-.55-.59-1-1.31-.85s-.61.8-.55,1.36A10.24,10.24,0,0,1,13.8,10c0,1-.42,1.33-1.34,1.3s-1.2-.37-1.21-1.34c0-2.7,0-5.4,0-8.1,0-1.48.24-1.71,1.69-1.65ZM13.82,4c0,.37-.11,1,.91,1s1.61-.49,1.62-1.32S15.7,2.32,14.7,2.34,13.8,3,13.82,4Z"
          />
          <path
            fill="#299dd5"
            d="M10.19,11.82q0,5.1,0,10.2c0,1.2-.23,1.46-1.22,1.46S7.6,23.17,7.6,22q0-9.35,0-18.68c0-.47,0-.86-.57-1-.33-.09-.82,0-.73-.56S6.43.49,7.15.28a5.73,5.73,0,0,1,2.44,0c.56.08.58.63.59,1.09,0,1.83,0,3.65,0,5.47s0,3.34,0,5Z"
          />
          <path
            fill="#515151"
            className="dark-img"
            d="M57.52,12.37c1.41,0,1.47,0,1.93,1.39,1,2.9,2,5.8,3,8.68.26.74.05,1-.66,1-1.23.11-1.88-.25-2.14-1.45a1.23,1.23,0,0,0-1.6-1.14,3.39,3.39,0,0,1-.64,0c-1.69,0-1.69,0-2.2,1.64a1.91,1.91,0,0,1-2.62.68c-.29-.26-.19-.54-.07-.83,1.12-2.82,2-5.72,3-8.58C56,12.42,56.07,12.38,57.52,12.37Zm-.12,2.84c-.33,1-.65,1.9-.87,2.79-.16.65.39.75.85.76s1.32,0,1.17-.59A6.06,6.06,0,0,0,57.4,15.21Z"
          />
          <path
            fill="#515151"
            className="dark-img"
            d="M24.24,0A8.12,8.12,0,0,1,27,.51a.67.67,0,0,1,.5,1c-.23.54-.33,1.26-1.21,1.06-.56-.13-1.1-.32-1.67-.42a1,1,0,0,0-1.31.68,1,1,0,0,0,.64,1.32c.79.41,1.57.8,2.33,1.25a2.93,2.93,0,0,1,1.48,3,3,3,0,0,1-2.09,2.62,7.22,7.22,0,0,1-4.12.07A1.73,1.73,0,0,1,20.5,9c.2-.51.59-.44,1-.29a5.64,5.64,0,0,0,2.18.57c.67,0,1.32-.09,1.51-.87s-.37-1-.88-1.31-1-.53-1.53-.81c-1.7-.92-2.35-2.06-2.08-3.64S21.89.31,23.87,0Z"
          />
          <path
            fill="#515151"
            className="dark-img"
            d="M45.78,23.6c-3.53,0-5.21-1.94-5.27-5.72-.07-4.27,3.61-6.72,7.56-5.24.69.25,1,.56.7,1.34s-.58,1.21-1.46.77c-2.18-1.08-3.9.23-4.12,2.57a7.84,7.84,0,0,0,0,1.37c.17,2,1.25,2.91,3.28,2.66.33-.05.66-.09,1-.12s1-.41,1.14.28A1.32,1.32,0,0,1,48,23.18,5.37,5.37,0,0,1,45.78,23.6Z"
          />
          <path
            fill="#515151"
            className="dark-img"
            d="M31.16,7c0-1,0-1.94,0-2.91,0-1.45-.33-1.8-1.79-1.69-.8.06-1.23-.2-1.25-1S28.41.17,29.19.17h6.48c.78,0,1.07.45,1,1.18a.92.92,0,0,1-1.11,1c-1.67,0-1.9.2-1.88,1.85L33.78,10c0,.94-.34,1.24-1.38,1.24s-1.26-.26-1.26-1.22,0-2.07,0-3.1Z"
          />
          <path
            fill="#515151"
            className="dark-img"
            d="M0,5.65c0-1.49,0-3,0-4.46C0,.6.21.31.85.29a30.17,30.17,0,0,1,3.92,0c.4,0,1.1-.2,1,.64S5.52,2.26,4.66,2.33a8.34,8.34,0,0,1-1,0c-.85,0-1.15.43-1.14,1.18s.2,1.35,1.1,1.37c.18,0,.36,0,.55,0,1,0,1.27.2,1.27,1.08S5.16,7.07,4.18,7.05c-1.62,0-1.75.1-1.61,1.69a6.64,6.64,0,0,1,0,1.18C2.5,11,2.25,11.3,1.32,11.27S0,10.93,0,9.84c0-1.4,0-2.79,0-4.19Z"
          />
          <path
            fill="#515151"
            className="dark-img"
            d="M.12,20.63c-.31-2.09.11-4.39-.1-6.69-.11-1.26.25-1.58,1.49-1.58s2.19,0,3.28,0c.72,0,1.16.22,1.19,1s-.42,1.08-1.11,1.12a6.65,6.65,0,0,1-1.08-.09,1.08,1.08,0,0,0-1.27.9,1.16,1.16,0,0,0,.73,1.55,5.36,5.36,0,0,0,1.34.21c.75.05.85.54.85,1.14s-.31.95-1,.92l-.73,0c-.91,0-1.25.37-1.23,1.29,0,.66,0,1.33.05,2a.94.94,0,0,1-1,1.09c-1,.07-1.5-.21-1.6-.92a6.91,6.91,0,0,1,0-.91C.05,21.38.08,21.11.12,20.63Z"
          />
          <path
            fill="#515151"
            className="dark-img"
            d="M63,18V13.67c0-1,.23-1.27,1.2-1.28s1.37.23,1.36,1.31c0,1.85.06,3.7-.1,5.55s.45,2.38,2.28,2.12A11.5,11.5,0,0,1,69,21.3c.78,0,1.06.43,1,1.16s-.41,1-1.11,1c-1.61,0-3.22,0-4.83,0-.73,0-1-.34-1-1.06,0-1.49,0-3,0-4.47Z"
          />
          <path
            fill="#515151"
            className="dark-img"
            d="M52,17.94c0,1.46,0,2.91,0,4.36,0,.89-.25,1.15-1.21,1.18s-1.34-.18-1.35-1.12q0-4.44,0-8.9c0-.86.33-1.11,1.3-1.09s1.31.32,1.29,1.22C52,14.31,52,15,52,15.76s0,1.46,0,2.18Z"
          />
        </g>
      </g>
    </svg>
  );
}
