import React from "react";

export default function StaufferLogoSvg() {
  return (
    <svg className="w-full h-full dark-img" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 70 13">
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <path d="M56.6,5.5c-.46-2.74.48-4.63,2.55-5.3s3.79.36,4.92,2.9A1.92,1.92,0,0,1,66.52,3a2.15,2.15,0,0,1,.78,2.42l.6.17A3.11,3.11,0,0,1,70,8.92a2.93,2.93,0,0,1-2.87,2.66c-3.81,0-7.63,0-11.44,0a3,3,0,0,1-2.94-3.25,3,3,0,0,1,3.43-2.87Zm5.48,1.76,1-.75c-.94.14-1.5-.06-1.68-1-.09,1-.77,1.09-1.52,1.1.83.31.93.86.64,1.62.63-.64,1.21-.5,1.9.1Z" />
          <path d="M29.23,9.72c-.95-1-.95-1.48-.05-2.54q-1.12-1.81-.12-3.06a3.13,3.13,0,0,1,3.59-.59A20.72,20.72,0,0,1,34.78,3a7.5,7.5,0,0,1,.29,1,6.21,6.21,0,0,0-.89,0c-.38.06-.54.23-.41.71a2.18,2.18,0,0,1-2,3c-.5,0-1-.06-1.52,0-.19,0-.51.16-.52.28a.77.77,0,0,0,.22.64,2.29,2.29,0,0,0,.94.24c.81.06,1.63,0,2.43.16a1.64,1.64,0,0,1,1.49,1.47,1.84,1.84,0,0,1-1.06,2,6.25,6.25,0,0,1-4.11.3,1.57,1.57,0,0,1-.44-3.07Zm2.33.28,0-.15a7.18,7.18,0,0,0-1.75.57A.7.7,0,0,0,30,11.75a12.55,12.55,0,0,0,3,0c.25,0,.6-.59.6-.91s-.43-.6-.73-.71A4.88,4.88,0,0,0,31.56,10Zm-.34-3.21c1,0,1.52-.41,1.54-1.24s-.5-1.29-1.47-1.31-1.52.42-1.54,1.24S30.24,6.78,31.22,6.79Z" />
          <path d="M40.35,10.07V6.23c0-1.36-.35-1.86-1.26-1.85a2.16,2.16,0,0,0-2.08,2c0,1.17,0,2.33,0,3.5a1.18,1.18,0,0,1-.06.19H35.82V.65H37V4.08a10.63,10.63,0,0,1,2.25-.79,2,2,0,0,1,2.3,2c.1,1.57,0,3.16,0,4.79Z" />
          <path d="M16.62,10.1h-1l-.15-.65a13.16,13.16,0,0,1-2.49.81A1.7,1.7,0,0,1,11,8.93a2,2,0,0,1,1-2.34A10.23,10.23,0,0,1,14.16,6a9.43,9.43,0,0,1,1.24-.13,1.27,1.27,0,0,0-.69-1.4C13.88,4,13,4.28,12,5.21l-.68-.56a3.09,3.09,0,0,1,3.55-1.31,2.27,2.27,0,0,1,1.79,2.5C16.63,7.24,16.62,8.64,16.62,10.1ZM15.37,6.68a24.1,24.1,0,0,0-2.48.71,1,1,0,0,0-.57,1.31c.21.58.7.62,1.21.54C14.66,9.06,15.33,8.2,15.37,6.68Z" />
          <path d="M5.56,4.43l-.68.74a5,5,0,0,0-1.18-.7,3,3,0,0,0-1.55,0c-.3.08-.51.54-.76.83.27.24.51.59.82.69.75.22,1.56.25,2.3.5a1.68,1.68,0,0,1,1.1,2.33A2.7,2.7,0,0,1,4.45,10,3.9,3.9,0,0,1,0,9l.6-.67a8.58,8.58,0,0,0,1.84.85A3.2,3.2,0,0,0,4.05,9a1,1,0,0,0,.47-.79,1,1,0,0,0-.58-.69c-.63-.2-1.3-.26-1.94-.4C.74,6.84.17,6.23.23,5.23a2.1,2.1,0,0,1,2-2A3.83,3.83,0,0,1,5.56,4.43Z" />
          <path d="M8.55,4.51V8.59c0,.48.29.65.71.62s.64-.08,1-.13c.19,1,.12,1.11-.75,1.2-1.54.16-2.13-.39-2.13-2,0-1.19,0-2.38,0-3.57a1.22,1.22,0,0,0-.07-.2L6.18,4.41V3.48L7.32,3.4l.2-1.79h1V3.4H10.2v1Z" />
          <path d="M44.82,1.61V3.42h1.7v1H44.85c0,1.45,0,2.81,0,4.17,0,.21.4.5.65.55a2.74,2.74,0,0,0,1-.1c.24,1,.14,1.13-.83,1.21-1.5.11-2-.4-2-2V4.54l-1.14-.12V3.49l1.13-.1.19-1.78Z" />
          <path d="M22.09,3.29V4.38a2.62,2.62,0,0,0-2.4,2.18,25.51,25.51,0,0,0-.38,3.5H18.25V3.47h1.13V5.22C20.09,4.05,20.72,3.13,22.09,3.29Z" />
          <path d="M24.21.65v9.42H23.08V.65Z" />
          <path d="M27.05,10.08H25.92V3.47h1.13Z" />
          <path d="M27,.63V2.15h-1.1V.63Z" />
        </g>
      </g>
    </svg>
  );
}
