import React from "react";

export default function ProfolioXLogoSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 70 24.76"
      width="70"
      height="24.76"
      className="w-full h-full"
    >
      <defs>
        <style>
          {`.ProfolioX-1{fill:none;}
            .ProfolioX-3{fill:#1e2120;}
            .ProfolioX-4{fill:#0075a3;}
            .ProfolioX-5{fill:#fff;}
            .ProfolioX-6{fill:#482466;}`}
        </style>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g xmlns="http://www.w3.org/2000/svg" class="ProfolioX-2">
          <path
            class="ProfolioX-3 dark-img"
            d="M24.6,12.52v3.21H23.52V7.52h3a3,3,0,0,1,2.11.69A2.33,2.33,0,0,1,29.43,10a2.29,2.29,0,0,1-.75,1.84,3.18,3.18,0,0,1-2.14.65Zm0-.89h1.95a2,2,0,0,0,1.33-.41A1.49,1.49,0,0,0,28.34,10a1.54,1.54,0,0,0-.46-1.18,1.88,1.88,0,0,0-1.27-.46h-2Z"
          />
          <path
            class="ProfolioX-3 dark-img"
            d="M33.6,10.57a2.82,2.82,0,0,0-.52,0,1.37,1.37,0,0,0-1.39.87v4.33h-1V9.63h1l0,.7a1.63,1.63,0,0,1,1.46-.81,1.14,1.14,0,0,1,.46.07Z"
          />
          <path
            class="ProfolioX-3 dark-img"
            d="M34.17,12.62A3.69,3.69,0,0,1,34.52,11a2.63,2.63,0,0,1,1-1.1,2.68,2.68,0,0,1,1.44-.39,2.57,2.57,0,0,1,2,.86,3.35,3.35,0,0,1,.77,2.3v.07a3.62,3.62,0,0,1-.35,1.6,2.54,2.54,0,0,1-1,1.1,2.72,2.72,0,0,1-1.46.39,2.54,2.54,0,0,1-2-.86,3.28,3.28,0,0,1-.77-2.28v-.08Zm1,.13a2.59,2.59,0,0,0,.47,1.63,1.61,1.61,0,0,0,2.53,0,2.89,2.89,0,0,0,.47-1.75A2.56,2.56,0,0,0,38.21,11a1.51,1.51,0,0,0-1.27-.63,1.5,1.5,0,0,0-1.25.62A2.8,2.8,0,0,0,35.22,12.75Z"
          />
          <path
            class="ProfolioX-3 dark-img"
            d="M41.55,15.73V10.44h-1V9.63h1V9a2.06,2.06,0,0,1,.53-1.51A2,2,0,0,1,43.56,7a2.55,2.55,0,0,1,.72.1l-.06.84a3.33,3.33,0,0,0-.56,0,1.07,1.07,0,0,0-.79.29A1.22,1.22,0,0,0,42.6,9v.65h1.3v.81H42.6v5.29Z"
          />
          <path
            class="ProfolioX-3 dark-img"
            d="M44.77,12.62A3.56,3.56,0,0,1,45.13,11a2.56,2.56,0,0,1,1-1.1,2.67,2.67,0,0,1,1.43-.39,2.55,2.55,0,0,1,2,.86,3.3,3.3,0,0,1,.77,2.3v.07a3.61,3.61,0,0,1-.34,1.6,2.56,2.56,0,0,1-1,1.1,2.71,2.71,0,0,1-1.45.39,2.57,2.57,0,0,1-2-.86,3.33,3.33,0,0,1-.77-2.28v-.08Zm1,.13a2.59,2.59,0,0,0,.48,1.63,1.6,1.6,0,0,0,2.52,0,2.89,2.89,0,0,0,.47-1.75A2.56,2.56,0,0,0,48.81,11a1.5,1.5,0,0,0-1.27-.63A1.48,1.48,0,0,0,46.3,11,2.79,2.79,0,0,0,45.82,12.75Z"
          />
          <path class="ProfolioX-3 dark-img" d="M52.78,15.73h-1V7.07h1Z" />
          <path
            class="ProfolioX-3 dark-img"
            d="M54.45,8a.65.65,0,0,1,.16-.43A.62.62,0,0,1,55.69,8a.57.57,0,0,1-.62.59.56.56,0,0,1-.46-.17A.61.61,0,0,1,54.45,8Zm1.13,7.72h-1V9.63h1Z"
          />
          <path
            class="ProfolioX-3 dark-img"
            d="M57,12.62A3.69,3.69,0,0,1,57.33,11a2.63,2.63,0,0,1,1-1.1,2.68,2.68,0,0,1,1.44-.39,2.57,2.57,0,0,1,2,.86,3.35,3.35,0,0,1,.77,2.3v.07a3.76,3.76,0,0,1-.34,1.6,2.62,2.62,0,0,1-1,1.1,2.72,2.72,0,0,1-1.46.39,2.54,2.54,0,0,1-2-.86A3.28,3.28,0,0,1,57,12.7v-.08Zm1.05.13a2.65,2.65,0,0,0,.47,1.63,1.61,1.61,0,0,0,2.53,0,2.89,2.89,0,0,0,.47-1.75A2.56,2.56,0,0,0,61,11a1.51,1.51,0,0,0-1.27-.63A1.5,1.5,0,0,0,58.5,11,2.86,2.86,0,0,0,58,12.75Z"
          />
          <path
            class="ProfolioX-4"
            d="M66.69,10.67l2-3.15h1.28l-2.6,4.07L70,15.73H68.72l-2-3.2-2,3.2H63.38l2.67-4.14L63.44,7.52h1.27l2,3.15Z"
          />
          <path
            class="ProfolioX-4"
            d="M0,10l5.27,5.26V9.18H7.18v8L9,19V4.36h1.91V19.11l1.85-1.85V9.18H14.7v6.17L20.05,10,10,0Z"
          />
          <path class="ProfolioX-5" d="M12.79,9.18v8.08l1.91-1.91V9.18Z" />
          <path class="ProfolioX-5" d="M9,4.36V19l1,1,.92-.91V4.36Z" />
          <path class="ProfolioX-5" d="M5.27,9.18v6.09l1.91,1.91v-8Z" />
          <path class="ProfolioX-6 dark-txt" d="M12.79,21.27H14.7V15.35l-1.91,1.91Z" />
          <path class="ProfolioX-6 dark-txt" d="M9,19v5.73h1.91V19.11L10,20Z" />
          <path class="ProfolioX-6 dark-txt" d="M5.27,21.27H7.18V17.18L5.27,15.27Z" />
        </g>
      </g>
    </svg>
  );
}
