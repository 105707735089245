import React from "react";

export default function  AlltoldLogoSvg() {
  return (
    <svg
      xmlns="http://www.w3.org/2000/svg"
      Xlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 70 19.35"
      className="w-full h-full"
    >
      <defs>
        <linearGradient
          id="linear-gradient"
          x1="12.22"
          y1="-140.48"
          x2="0.55"
          y2="-123.18"
          gradientTransform="matrix(1, 0, 0, -1, 0, -120.61)"
          gradientUnits="userSpaceOnUse"
        >
          <stop offset="0" stopColor="#e900c2" />
          <stop offset="0.21" stopColor="#ee3aa7" />
          <stop offset="0.49" stopColor="#f58287" />
          <stop offset="0.73" stopColor="#fab76f" />
          <stop offset="0.9" stopColor="#fdd760" />
          <stop offset="1" stopColor="#ffe45b" />
        </linearGradient>
      </defs>
      <g id="Layer_2" data-name="Layer 2">
        <g id="Layer_1-2" data-name="Layer 1">
          <g id="Components">
            <g
              id="e44bd6f2-f991-4ca2-ba6a-f116436a11f1_1"
              data-name="e44bd6f2-f991-4ca2-ba6a-f116436a11f1 1"
            >
              <path
                fill="#202060"
                className="dark-img"
                d="M49.68,9.1a3.08,3.08,0,1,1-3.08,3.08A3.08,3.08,0,0,1,49.68,9.1m0-2a5.13,5.13,0,1,0,5.12,5.13,5.13,5.13,0,0,0-5.12-5.13Z"
              />
              <path
                fill="#202060"
                className="dark-img"
                d="M44.34,7.54H42.49V5.26a.15.15,0,0,0-.15-.15H40.59a.15.15,0,0,0-.15.15V7.54h0V9.59h0v6.68h0a5.11,5.11,0,0,0,1.05,3,.23.23,0,0,0,.32,0l1.26-1a.22.22,0,0,0,0-.31,3,3,0,0,1-.63-1.85V9.59h1.33A.15.15,0,0,0,44,9.48l.52-1.75a.14.14,0,0,0-.14-.19Z"
              />
              <path
                fill="#202060"
                className="dark-img"
                d="M30,7.05H28.35a.2.2,0,0,0-.2.2v.83a5.07,5.07,0,0,0-3.07-1,5.13,5.13,0,1,0,3.07,9.23v.82a.2.2,0,0,0,.2.2H30a.2.2,0,0,0,.2-.2V7.25A.2.2,0,0,0,30,7.05Zm-4.92,8.2a3.08,3.08,0,1,1,3.07-3.07A3.08,3.08,0,0,1,25.08,15.25Z"
              />
              <path
                fill="#202060"
                className="dark-img"
                d="M34.3,15.25V3.15a.2.2,0,0,0-.2-.2H32.45a.2.2,0,0,0-.2.2v14a.2.2,0,0,0,.2.2H34.1a.2.2,0,0,0,.2-.2Z"
              />
              <path
                fill="#202060"
                className="dark-img"
                d="M38.4,15.25V3.15a.2.2,0,0,0-.2-.2H36.55a.2.2,0,0,0-.2.2v14a.2.2,0,0,0,.2.2H38.2a.2.2,0,0,0,.2-.2Z"
              />
              <path
                fill="#202060"
                className="dark-img"
                d="M58.3,15.25V3.15a.2.2,0,0,0-.2-.2H56.45a.2.2,0,0,0-.2.2v14a.2.2,0,0,0,.2.2H58.1a.2.2,0,0,0,.2-.2Z"
              />
              <path
                fill="#202060"
                className="dark-img"
                d="M69.8,3H68.15a.2.2,0,0,0-.2.2V8.08a5.12,5.12,0,1,0-3.08,9.22,5.13,5.13,0,0,0,3.08-1v.82a.2.2,0,0,0,.2.2H69.8a.2.2,0,0,0,.2-.2V3.15A.2.2,0,0,0,69.8,3Zm-4.93,12.3A3.08,3.08,0,1,1,68,12.18,3.08,3.08,0,0,1,64.87,15.25Z"
              />
              <path
                fill="url(#linear-gradient)"
                d="M3.35,1.47A1.47,1.47,0,0,1,4.82,0H9.2a1.48,1.48,0,0,1,0,3H4.82A1.47,1.47,0,0,1,3.35,1.47ZM13.86,17.22,8.32,6.15A1.45,1.45,0,0,0,7,5.34H7a1.46,1.46,0,0,0-1.32.81L.16,17.22a1.47,1.47,0,0,0,1.32,2.13,1.47,1.47,0,0,0,1.32-.81L6.08,12a1,1,0,0,1,1.85,0l3.28,6.55a1.47,1.47,0,0,0,1.33.81,1.48,1.48,0,0,0,1.32-2.13Z"
              />
            </g>
          </g>
        </g>
      </g>
    </svg>
  );
}
