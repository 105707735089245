import {
  TestimonialsArray,
  TestimonialsArrayThree,
  TestimonialsArrayTwo,
} from "@/DummyData";
import {
  RecommendedInnerCol,
  RecommendedInnerRow,
  RecommendedInnerSide,
} from "@/StyledComponents/LandingStyled";
import gsap from "gsap";
import { ScrollTrigger } from "gsap/ScrollTrigger";
import Image from "next/image";
import { useEffect, useRef, useState } from "react";
import { Container } from "reactstrap";
import ClutchReviewTitleSec from "./ClutchReviewTitleSec";

// Import SVG Logos
import FirstfinancialLogoSvg from "../svg/FirstfinancialLogoSvg";
import NASCARLogoSvg from "../svg/NASCARLogoSvg";
import StaufferLogoSvg from "../svg/StaufferLogoSvg";
import FurgonetkaLogoSvg from "../svg/FurgonetkaLogoSvg";
import ProfolioXLogoSvg from "../svg/ProfolioXLogoSvg";
import RickAICloudLogoSvg from "../svg/RickAICloudLogoSvg";
import AlltoldLogoSvg from "../svg/AlltoldLogoSvg";
import OverDriveLogoSvg from "../svg/OverDriveLogoSvg";

gsap.registerPlugin(ScrollTrigger);

// Map usernames to their respective logo components
const logoMap = {
  "Jacob Webb": <StaufferLogoSvg />,
  "Andrzej Ciesielski": <FurgonetkaLogoSvg />,
  "Eric Ryan": <NASCARLogoSvg />,
  "Chris Crough": <FirstfinancialLogoSvg />,
  "Kevin Edward": <ProfolioXLogoSvg />,
  "Rick Blaisdell": <RickAICloudLogoSvg />,
  "Morgan Gregory": <AlltoldLogoSvg />,
  "Dennis Stauffer": <OverDriveLogoSvg />,
};

const LogoRenderer = ({ uname, company }) => {
  return (
    <div className="flex w-[30%] flex-col justify-end items-end flex-nowrap">
      {logoMap[uname] || 
        (uname === "Dennis Hefter" && (
          <Image src={company} width={70} height={50} alt={uname} className="w-full" />
        ))}
    </div>
  );
};

export default function ClutchReviewSection() {
  const [posterLoaded, setPosterLoaded] = useState(false);
  const videoRef1 = useRef(null);
  const videoRef2 = useRef(null);
  const containerRef = useRef(null);

  // Lazy-load poster image when user interacts with page
  useEffect(() => {
    const enableLazyLoad = () => setPosterLoaded(true);
    ["mousemove", "click", "scroll"].forEach(event =>
      window.addEventListener(event, enableLazyLoad)
    );

    return () => {
      ["mousemove", "click", "scroll"].forEach(event =>
        window.removeEventListener(event, enableLazyLoad)
      );
    };
  }, []);

  // GSAP animation for testimonials
  useEffect(() => {
    const columns = gsap.utils.toArray(".testimonial-column");
    columns.forEach(column => {
      gsap.fromTo(
        column,
        { opacity: 0, y: 100 },
        {
          opacity: 1,
          y: 0,
          duration: 1,
          ease: "power3.out",
          scrollTrigger: {
            trigger: column,
            start: "top 90%",
            toggleActions: "play none none reverse",
          },
        }
      );
    });
  }, []);

  return (
    <Container className="p-b-section" ref={containerRef}>
      <RecommendedInnerSide className="!mt-0">
        <ClutchReviewTitleSec />
        <RecommendedInnerRow>
          {[TestimonialsArray, TestimonialsArrayTwo, TestimonialsArrayThree].map((array, index) => (
            <RecommendedInnerCol key={index} className="testimonial-column">
              <div className="flex flex-col w-full">
                {index === 0 && (
                  <div className="rounded-[16px] text-[--text-color-dark] mt-3 mb-3 lg:mt-5 lg:mb-5 border-0 border-[--border-color]">
                    <div ref={videoRef1} className="flex flex-row items-center justify-between">
                      <video
                        className="rounded-[16px] w-full h-auto"
                        width="392"
                        height="240"
                        controls
                        preload="none"
                        poster={posterLoaded ? "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/About-Video-1.webp" : ""}
                      >
                        <source
                          src="https://d3nxqgkzw2f14c.cloudfront.net/itservicesindia/videos/TestimonialforITServicesIndia-01.mp4"
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                )}

                {array.map((m) => (
                  <div
                    key={m.id}
                    className={`testimonial-light py-4 lg:py-5 px-4 lg:px-5 mt-3 mb-3 lg:mt-5 lg:mb-5 rounded-[16px] border-1 border-[--border-color] ${
                      m.id === "2" ? "bg-[m.bg]" : "bg-[#f6f6f6] text-[--text-color]"
                    }`}
                  >
                    <p className="font-['Merriweather'] text-[14px] font-normal leading-[26px] tracking-[0.3px] antialiased">{m.content}</p>
                    <div className="flex flex-row items-center justify-between mt-5">
                      <div className="flex flex-row items-center gap-x-2 lg:gap-x-4">
                        <Image
                          src={m.img}
                          width={45}
                          height={50}
                          alt={m.uname}
                          className="rounded-[50%] border-[0.5px] border-[#d2d2d2]"
                        />
                        <div className="flex flex-col">
                          <p className="font-medium text-[12px]">{m.uname}</p>
                          <p className="text-[12px] text-[#737373]">{m.position}</p>
                        </div>
                      </div>
                      <LogoRenderer uname={m.uname} company={m.company} />
                    </div>
                  </div>
                ))}

                {index === 1 && (
                  <div className="rounded-[16px] text-[--text-color-dark] mt-3 mb-3 lg:mt-0 lg:mb-5 border-0 border-[--border-color]">
                    <div ref={videoRef2} className="flex flex-row items-center justify-between">
                      <video
                        className="rounded-[16px] w-full h-auto"
                        width="392"
                        height="240"
                        controls
                        preload="none"
                        poster={posterLoaded ? "https://d31kzidzn6t9lk.cloudfront.net/itservicesindia/parth-thumbnail-2.webp" : ""}
                      >
                        <source
                          src="https://d3nxqgkzw2f14c.cloudfront.net/itservicesindia/videos/02_Parth_Astro_Testimonial.mp4"
                          type="video/mp4"
                        />
                        Your browser does not support the video tag.
                      </video>
                    </div>
                  </div>
                )}
              </div>
            </RecommendedInnerCol>
          ))}
        </RecommendedInnerRow>
      </RecommendedInnerSide>
    </Container>
  );
}
